import {EnvelopeIcon, PhoneIcon } from '@heroicons/react/20/solid'

export function Support() {
 return (
  <div className="mt-72">
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <div className="max-w-lg mx-auto md:max-w-none md:grid md:grid-cols-2 md:gap-8">
      <div>
        <h2 className="text-2xl font-extrabold text-white sm:text-3xl">Sales Support</h2>
        <div className="mt-3">
          <p className="text-lg text-sec">
            Contact sales to see how SwiftFiber can help you.
          </p>
        </div>
        <div className="mt-9">
          <div className="flex">
            <div className="flex-shrink-0">
              <PhoneIcon className="h-6 w-6 text-primary" aria-hidden="true" />
            </div>
            <div className="ml-3 text-base text-sec">
              <p>(03) 9988 7915</p>
              <p className="mt-1">Mon-Fri 9am to 8pm AEST</p>
            </div>
          </div>
          <div className="mt-6 flex">
            <div className="flex-shrink-0">
              <EnvelopeIcon className="h-6 w-6 text-primary" aria-hidden="true" />
            </div>
            <div className="ml-3 text-base text-sec">
              <p>Sales@SwiftFiber.com.au</p>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-12 sm:mt-16 md:mt-0">
        <h2 className="text-2xl font-extrabold text-white sm:text-3xl">Technical Support</h2>
        <div className="mt-3">
          <p className="text-lg text-sec">
            Contact our Australian support team with any issue you have.
          </p>
        </div>
        <div className="mt-9">
          <div className="flex">
            <div className="flex-shrink-0">
              <PhoneIcon className="h-6 w-6 text-primary" aria-hidden="true" />
            </div>
            <div className="ml-3 text-base text-sec">
              <p>(03) 9988 7915</p>
              <p className="mt-1">Mon-Sun 9am to 10pm AEST</p>
            </div>
          </div>
          <div className="mt-6 flex">
            <div className="flex-shrink-0">
              <EnvelopeIcon className="h-6 w-6 text-primary" aria-hidden="true" />
            </div>
            <div className="ml-3 text-base text-sec">
              <p>Support@SwiftFiber.com.au</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
 )
}