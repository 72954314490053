import {Footer} from '../components/footer';
import {Nav} from '../components/nav';
import {Reviews} from '../components/reviews';
import {Support} from '../components/support';
import {Network} from '../components/network';
import {
  ArrowPathIcon,
  Bars3Icon,
  ChartBarIcon,
  CursorArrowRaysIcon,
  DocumentChartBarIcon,
  ShieldCheckIcon,
  XMarkIcon,
  MegaphoneIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, ArrowRightIcon, ArrowsRightLeftIcon, CheckIcon, EnvelopeIcon, PhoneIcon, CheckCircleIcon, ChevronRightIcon } from '@heroicons/react/20/solid'

const vpsplans = [
  { name: 'gp.xsmall', cpu: '1 vCore', memory: '2GB', storage: '25GB SSD', bandwidth: '500GB', price: 6 },
  { name: 'gp.small', cpu: '1 vCore', memory: '4GB', storage: '55GB SSD', bandwidth: '1TB', price: 10 },
  { name: 'gp.medium', cpu: '2 vCore', memory: '8GB', storage: '80GB SSD', bandwidth: '1TB', price: 20 },
  { name: 'gp.large', cpu: '4 vCore', memory: '8GB', storage: '120GB SSD', bandwidth: '3TB', price: 40 },
  { name: 'gp.xlarge', cpu: '6 vCore', memory: '16GB', storage: '200GB SSD', bandwidth: '4TB', price: 60 },
  { name: 'gp.xxlarge', cpu: '8 vCore', memory: '16GB', storage: '300GB SSD', bandwidth: '5TB', price: 80 },
  // More people...
]


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}



export function Albury() {
  return (


<>
{/* <div className="fixed inset-x-0 bottom-0 pb-2 sm:pb-5 z-50">
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div className="rounded-lg bg-primary p-2 shadow-lg sm:p-3">
          <div className="flex flex-wrap items-center justify-between">
            <div className="flex w-0 flex-1 items-center">
              <span className="flex rounded-lg bg-primarydark p-2">
                <MegaphoneIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </span>
              <p className="ml-3 truncate font-medium text-white">
                <span className="md:hidden">1000Mbps internet now in Albury!</span>
                <span className="hidden md:inline">Wifiber 1000Mbps mmWave is now available in South Albury!</span>
              </p>
            </div>
            <div className="order-3 mt-2 w-full flex-shrink-0 sm:order-2 sm:mt-0 sm:w-auto">
              <a
                href="#"
                className="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-2 text-sm font-medium text-primary shadow-sm hover:bg-indigo-50"
              >
                Learn more
              </a>
            </div>
            <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
              <button
                type="button"
                className="-mr-1 flex rounded-md p-2 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white"
              >
                <span className="sr-only">Dismiss</span>
                <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div> */}
    
<div className="container mx-auto font-poppins">





<Nav />




<div className="mt-36 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mb-72">
  <div className="flex justify-center">
    <span className="text-white text-8xl font-semibold"><span className="text-primary">Albury</span> NSW</span>
  </div>

  <div className="flex justify-center mt-10">
    <span className="text-white text-3xl font-medium">Most connected regional town powered by <span className="text-primary">SwiftFiber</span></span>
  </div>
  <div className="flex justify-center mt-10">
      <a
        href="https://cloud.swiftfiber.com.au"
        className="ml-5 inline-flex items-center rounded-md border border-transparent bg-primary px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        Get Started
        <ArrowRightIcon className="ml-3 -mr-1 h-5 w-5" aria-hidden="true" />
      </a>
  </div>

</div>




<div className="mt-72 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
  <div className="flex flex-col justify-between gap-4 gap-y-8 sm:flex-row sm:gap-x-12 sm:gap-y-0">
    <div className="flex justify-center">
      <div>
        <span className="text-white text-5xl font-semibold">Low<span className="text-primary">-</span>Latency network</span>
        <br/>
        <div className="mt-2"><span className="text-sec text-3xl font-medium">Best location for connectivity in australia</span></div>
        <br/>
        <br/>
        <span className="text-white text-2xl font-normal">Albury is in the perfect location to serve traffic from <br/> both Melbourne and Sydney with a total latency of ~6ms</span>
      </div>
    </div>
    <div className="flex justify-center">
      <div className="bg-bgsec rounded-2xl">
        <div className="px-16 py-10">
          <span className="text-white text-3xl font-medium">Take a look under the hood</span>
          <br/>
          <br/>
          <br/>

        <div className="flex justify-between">
          <div className="flex justify-start align-center items-center">
            <span className="text-white text-1xl font-normal mr-2">Albury</span>
            <ArrowsRightLeftIcon className="h-5 w-5 text-primary" aria-hidden="true" />
            <span className="text-white text-1xl font-normal ml-2">Sydney</span>
          </div>
          <div >
            <span className="text-white text-1xl font-normal">6ms</span>
          </div>
        </div>
        <div className="flex justify-between mt-2">
          <div className="flex justify-start align-center items-center">
            <span className="text-white text-1xl font-normal mr-2">Albury</span>
            <ArrowsRightLeftIcon className="h-5 w-5 text-primary" aria-hidden="true" />
            <span className="text-white text-1xl font-normal ml-2">Melbourne</span>
          </div>
          <div >
            <span className="text-white text-1xl font-normal">TBA</span>
          </div>
        </div>

        <div className="flex justify-center mt-6"><span className="text-sec text-md font-medium">Live stats from our network </span></div>
        </div>
      </div>
    </div>
    
  </div>
  <div className="flex flex-col items-center justify-between gap-4 gap-y-8 sm:flex-row sm:gap-x-12 sm:gap-y-0 mt-24">
    <div>
      <div><span className="text-white text-3xl font-semibold"><span className="text-primary">100+</span> Gbps DDoS Protection</span></div>
      <div className="mt-2"><span className="text-sec text-2xl font-medium"><span className="text-primary">Corero</span> inline DDoS Protection ensuring <br/> your network is always protected</span></div>
    </div>
    <div>
      <div><span className="text-white text-3xl font-semibold">Top Teir <span className="text-primary">Network</span></span></div>
      <div className="mt-2"><span className="text-sec text-2xl font-medium">All our locations are serviced by <a className="text-primary underline" href="https://globalsecurelayer.com">Global Secure Layer</a><br/> allowing us to have amazing network performance</span></div>
    </div>
  </div>
</div>


<div className="mt-72">

<div className="flex justify-center"><span className="text-white text-5xl font-semibold">Why Albury?</span></div>
<div className="flex justify-center"><span className="text-sec text-2xl font-medium mb-24">Albury is the best place to provide low latency services to both victoria and nsw.</span></div>


<div className="grid grid-rows-1 grid-flow-col gap-4">
  <div>
    <div className="mb-6">
      <center>
        <span className="text-white text-3xl font-semibold">SwiftFiber Network</span>
      </center>
    </div>
    <div className="flex justify-center items-center"><img src="https://swiftportal.s3.ap-southeast-2.amazonaws.com/alburyswift.svg" /></div>
  </div>
  <div>
    <div className="mb-6">
      <center>
        <span className="text-white text-3xl font-semibold">Everyone Else's Network</span>
      </center>
    </div>
    <div className="flex justify-center items-center"><img src="https://swiftportal.s3.ap-southeast-2.amazonaws.com/alburyother.svg" /></div>
  </div>
</div>



</div>



<Support />

<Footer/>
</div>

</>
  );
}

