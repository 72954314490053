

export function Reviews() {
 return (
<div className="mt-72">
      <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        {/* <svg
          className="absolute top-full right-full translate-x-1/3 -translate-y-1/4 transform lg:translate-x-1/2 xl:-translate-y-1/2"
          width={404}
          height={404}
          fill="none"
          viewBox="0 0 404 404"
          role="img"
          aria-labelledby="svg-workcation"
        >
          <title id="svg-workcation">Workcation</title>
          <defs>
            <pattern
              id="ad119f34-7694-4c31-947f-5c9d249b21f3"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width={404} height={404} fill="url(#ad119f34-7694-4c31-947f-5c9d249b21f3)" />
        </svg> */}

        <div className="relative">
          {/* <img
            className="mx-auto h-8"
            src="https://tailwindui.com/img/logos/workcation-logo-indigo-600-mark-gray-800-and-indigo-600-text.svg"
            alt="Workcation"
          /> */}
          <blockquote className="mt-10">
            <div className="mx-auto max-w-3xl text-center text-2xl font-medium leading-9 text-sec">
              <p>
                &ldquo;I've dealt with numerous hosting companies, you'd better believe I mean it when I say this but SwiftFiber is by far superior for performance, competitive pricing, and reliability. My Dedicated Servers load lightning-fast and the portal interface is superb. Ben has been really helpful and was able to work with my team to create the best plan for my team I believe I made the right call by switching hosting. Where would you find a hosting that offers free DDoS protection 600+ GB&rdquo;
              </p>
            </div>
            <footer className="mt-8">
              <div className="md:flex md:items-center md:justify-center">
                <div className="md:flex-shrink-0">
                  <img
                    className="mx-auto h-10 w-10 rounded-full"
                    src="https://avatars.dicebear.com/api/avataaars/retggyhsrhgfhfbvfghnbv.svg"
                    alt=""
                  />
                </div>
                <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                  <div className="text-base font-medium text-primary">Ashton</div>

                  <svg className="mx-1 hidden h-5 w-5 text-indigo-600 md:block" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M11 0h3L9 20H6l5-20z" />
                  </svg>

                  <div className="text-base font-medium text-gray-500">Omega RP</div>
                </div>
              </div>
            </footer>
          </blockquote>
        </div>
      </div>
</div>
 )
}