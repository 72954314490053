import {Footer} from '../components/footer';
import {Nav} from '../components/nav';
import { useParams } from "react-router-dom";
import { Fragment, useState, useEffect, useRef} from 'react'
import { Listbox, Transition, RadioGroup  } from '@headlessui/react'
import {
  ArrowPathIcon,
  Bars3Icon,
  ChartBarIcon,
  CursorArrowRaysIcon,
  DocumentChartBarIcon,
  ShieldCheckIcon,
  XMarkIcon,
  MegaphoneIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, ArrowRightIcon, ArrowsRightLeftIcon, CheckIcon, EnvelopeIcon, PhoneIcon, CheckCircleIcon, ChevronRightIcon } from '@heroicons/react/20/solid'



import axios from 'axios';
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}



export function Sq() {
  let { loc } = useParams();
  const [apidetails, setapidetails] = useState()
  const [addresslist, setaddresslist] = useState([])
  const [plan, setPlan] = useState([])

  useEffect(() => {

    axios.post("https://api.cloud.swiftfiber.com.au/v1/internetsq?locid=" + loc, {
    })
    .then((response) => {

      

      axios.post("https://api.cloud.swiftfiber.com.au/v1/internetsq?locid=" + response.data.suggestions[0].id, {
      })
      .then((response) => {
        setapidetails(response.data)
        setPlan(response.data.nbn?.plans[0])
     
      });
   
    });



  }, [])

    if(apidetails != null) { return (
<div className="container mx-auto font-poppins">
<Nav />
<>
<div className="container md:px-36 mt-36">





        <div className="overflow-hidden bg-bgsec shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-primary">Address Details</h3>
        <p className="mt-1 max-w-2xl text-sm text-sec">NBN details for {apidetails.address}.</p>
      </div>
      <div className="border-t border-[#646464] px-4 py-5 sm:p-0">
        <dl className="sm:divide-y sm:divide-[#646464]">
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-white">Address</dt>
            <dd className="mt-1 text-sm text-white sm:col-span-2 sm:mt-0">{apidetails.address}</dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-white">NBN Location ID</dt>
            <dd className="mt-1 text-sm text-white sm:col-span-2 sm:mt-0">{apidetails.loc}</dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-white">NBN Technology</dt>
            <dd className="mt-1 text-sm text-white sm:col-span-2 sm:mt-0">{apidetails.nbn.access} {apidetails.nbn.techtype}</dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-white">Service Class</dt>
            <dd className="mt-1 text-sm text-white sm:col-span-2 sm:mt-0">{apidetails.class}</dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-white">Estimated NBN Service Speed</dt>
            <dd className="mt-1 text-sm text-white sm:col-span-2 sm:mt-0">{apidetails?.nbn.download}/{apidetails?.nbn.upload} Mbps</dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-white">Current Provider</dt>
            <dd className="mt-1 text-sm text-white sm:col-span-2 sm:mt-0">{apidetails?.nbn.currentProvider}</dd>
          </div>
        </dl>
        
      </div>
    </div>






      <div className="hidden sm:block mt-10 mb-10" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-[#646464]" />
        </div>
      </div>

      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-primary">Plan</h3>
              <p className="mt-1 text-sm text-sec">How fast your internet will be.</p>
            </div>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
          <RadioGroup value={plan} onChange={setPlan}>
      <RadioGroup.Label className="sr-only"> NBN Plan </RadioGroup.Label>
      <div className="space-y-4">
        {apidetails.nbn.plans.map((plan) => (
          <RadioGroup.Option
            key={plan.name}
            value={plan}
            className={({ checked, active }) =>
              classNames(
                checked ? 'border-transparent' : 'border-gray-300',
                active ? 'border-indigo-500 ring-2 ring-indigo-500' : '',
                'relative block cursor-pointer rounded-lg border bg-bgsec px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between'
              )
            }
          >
            {({ active, checked }) => (
              <>
                <span className="flex items-center">
                  <span className="flex flex-col text-sm">
                    <RadioGroup.Label as="span" className="font-medium text-white">
                      {plan.name}
                      {(() => {
                        if (plan.recommended == 1) {
                            return (
                      <span className="ml-3 inline-flex items-center rounded bg-blue-100 px-2 py-0.5 text-xs font-medium text-blue-800">
                        Recommended
                      </span>
                            )}
                          })()}
                    </RadioGroup.Label>
                    <RadioGroup.Description as="span" className="text-sec">
                      <span className="block sm:inline">
                        {plan.speed} Mbps
                      </span>{' '}
                      <span className="hidden sm:mx-1 sm:inline" aria-hidden="true">
                        &middot;
                      </span>{' '}
                      <span className="block sm:inline">${plan.setup} Setup</span>
                    </RadioGroup.Description>
                  </span>
                </span>
                <RadioGroup.Description
                  as="span"
                  className="mt-2 flex text-sm sm:mt-0 sm:ml-4 sm:flex-col sm:text-right"
                >
                  <span className="font-medium text-green-400">${plan.price}</span>
                  <span className="ml-1 text-sec sm:ml-0">/mo</span>
                </RadioGroup.Description>
                <span
                  className={classNames(
                    active ? 'border' : 'border-2',
                    checked ? 'border-indigo-500' : 'border-[#646464]',
                    'pointer-events-none absolute -inset-px rounded-lg'
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
          </div>
        </div>
      </div>






      <div class="hidden sm:block mt-10 mb-10" aria-hidden="true"><div class="py-5"><div class="border-t border-[#646464]"></div></div></div>


      <div className="overflow-hidden bg-bgsec shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-primary">Service Overview</h3>
        <p className="mt-1 max-w-2xl text-sm text-sec">Details for your NBN service at {apidetails.address}.</p>
      </div>
      <div className="border-t border-[#646464] px-4 py-5 sm:p-0">
        <dl className="sm:divide-y sm:divide-[#646464]">
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-white">Address</dt>
            <dd className="mt-1 text-sm text-white sm:col-span-2 sm:mt-0">{apidetails.address}</dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-white">Plan</dt>
            <dd className="mt-1 text-sm text-white sm:col-span-2 sm:mt-0">{plan.name}</dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-white">Speed</dt>
            <dd className="mt-1 text-sm text-white sm:col-span-2 sm:mt-0">{plan.speed} Mbps</dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-white">Setup</dt>
            <dd className="mt-1 text-sm text-white sm:col-span-2 sm:mt-0">${plan.setup}</dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-white">Month Cost</dt>
            <dd className="mt-1 text-sm text-white sm:col-span-2 sm:mt-0">${plan.price}</dd>
          </div>
          <a
        href="https://cloud.swiftfiber.com.au/deploy/nbn"
        className="mr-5 mb-5 float-right inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        Login and checkout
      </a>
        </dl>
        
      </div>
    </div>



      </div>
</>
<Footer />
</div>
  );}else{
    return (
    <div className="container mx-auto font-poppins">
    <Nav />
    <div className="flex align-center items-center justify-center mt-72">

    <div role="status">
      <svg class="inline mr-2 w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-primary" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
      </svg>
    </div>
    <span className="text-white text-xl font-semibold ml-5">Loading <span className="text-primary">Great</span> Things</span>
    </div>
    <Footer />
    </div>
    )}
}

