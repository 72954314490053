import {useState} from 'react'
import { ArrowRightIcon } from '@heroicons/react/20/solid'
export function Search() {
  const [searchTerm, setSearchTerm] = useState('')
  function addresssq() {
   window.location.replace('/sq/' + searchTerm);
 }
 return (
<>
<div className="rounded-md border border-primary px-3 py-2 shadow-sm focus-within:border-primarydark focus-within:ring-1 focus-within:ring-primarydark w-6/12">
      <label htmlFor="name" className="block text-xs font-medium text-white">
        Address Search
      </label>
      <input
        type="text"
        onChange={(e) => setSearchTerm(e.target.value)}
        className="block w-full border-0 p-0 text-white placeholder-sec focus:ring-0 sm:text-sm bg-bg"
        placeholder="1 Swift Street Albury"
      />
    </div>
      <button
        type="button"
        onClick={() => addresssq()}
        className="ml-5 inline-flex items-center rounded-md border border-transparent bg-primary px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        Get Started
        <ArrowRightIcon className="ml-3 -mr-1 h-5 w-5" aria-hidden="true" />
      </button>
</>
 )
}