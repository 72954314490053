import {Footer} from '../components/footer';
import {Nav} from '../components/nav';
import {Reviews} from '../components/reviews';
import {Support} from '../components/support';
import {Network} from '../components/network';
import {Search} from '../components/addresssearch';
import {
  ArrowPathIcon,
  Bars3Icon,
  ChartBarIcon,
  CursorArrowRaysIcon,
  DocumentChartBarIcon,
  ShieldCheckIcon,
  XMarkIcon,
  MegaphoneIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, ArrowRightIcon, ArrowsRightLeftIcon, CheckIcon, EnvelopeIcon, PhoneIcon } from '@heroicons/react/20/solid'
import {useState} from 'react'
import React from 'react'
import  { Redirect } from 'react-router-dom'
const features = [
 {
   name: 'Uptime',
   description: 'SwiftFiber is currently maintaining a 100% uptime average.',
 },
 { name: 'DDoS Protection', description: 'SwiftFibers network is protected by 100Gbps of Corero DDoS mitigation.' },
 {
   name: 'Low Latency Network',
   description: 'SwiftFiber operates its own AS (AS149047) to provide awesome network performance.',
 },
 {
   name: 'Australian Owned',
   description: 'SwiftFiber is 100% Australian owned and operated.',
 },

]
const tiers = [
  {
    name: 'Wifiber Regional Internet',
    href: '/wifiber',
    priceMonthly: 79,
    description: 'Amazing speeds of up to 1000Mbps using SwiftFibers custom network.',
    includedFeatures: ['NBN not required','Up to 1000Mbps.', 'Low Latency.', 'Reliable.'],
  },
  {
    name: 'NBN',
    href: '/nbn',
    priceMonthly: 55,
    description: 'Internet access across australia using NBN™ and SwiftFibers backbone.',
    includedFeatures: [
      'Starting at 12Mbps.',
      'Nationwide - 121 POIs.',
      'Reliable.',
    ],
  },
  {
    name: 'Virtual Servers',
    href: '/virtualservers',
    priceMonthly: 6,
    description: 'Super Fast & Reliable Cloud Servers backed by SwiftFibers Great Network',
    includedFeatures: [
      '100Gbps DDoS Protection.',
      'SSD Storage.',
      'Custom Built Portal.',
    ],
  },
  {
    name: 'Dedicated Servers',
    href: '/dedicatedservers',
    priceMonthly: 48,
    description: 'High performant dedicated servers all to yourself',
    includedFeatures: [
      '100Gbps DDoS Protection.',
      'Low Latency Networking.',
      'Latest AMD & Intel CPUs.',
      'Uptime SLA.',
    ],
  },
]


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}




export function Home() {
 const [searchTerm, setSearchTerm] = useState('')
 function addresssq() {
  window.location.replace('/sq/' + searchTerm);
}
  return (


<>
{/* <div className="fixed inset-x-0 bottom-0 pb-2 sm:pb-5 z-50">
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div className="rounded-lg bg-primary p-2 shadow-lg sm:p-3">
          <div className="flex flex-wrap items-center justify-between">
            <div className="flex w-0 flex-1 items-center">
              <span className="flex rounded-lg bg-primarydark p-2">
                <MegaphoneIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </span>
              <p className="ml-3 truncate font-medium text-white">
                <span className="md:hidden">Albury Network Coming Soon</span>
                <span className="hidden md:inline">Albury Network Coming Soon</span>
              </p>
            </div>
            <div className="order-3 mt-2 w-full flex-shrink-0 sm:order-2 sm:mt-0 sm:w-auto">
              <a
                href="/location/albury"
                className="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-2 text-sm font-medium text-primary shadow-sm hover:bg-indigo-50"
              >
                Learn more
              </a>
            </div>
            <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
              <button
                type="button"
                className="-mr-1 flex rounded-md p-2 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white"
              >
                <span className="sr-only">Dismiss</span>
                <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
     */}
<div className="container mx-auto font-poppins">





<Nav />




<div className="mt-20 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mb-32">
  <div className="flex justify-center">
    <span className="text-white text-8xl font-semibold">Made for speed<span className="text-primary">.</span></span>
  </div>
  <div className="flex justify-center mt-8">
    <span className="text-white text-8xl font-semibold">
      Made for you<span className="text-primary">.</span>
    </span>
  </div>

  <div className="flex justify-center mt-10">
    <span className="text-white text-3xl font-medium">High performance internet & cloud servers ready for <span className="text-primary">anything</span></span>
  </div>
  <div className="flex justify-center mt-10">
<Search/>
  </div>
  <div className="flex justify-center mt-32">
    <span className="text-sec text-xl font-medium">Used by</span>
  </div>
  <div className="flex justify-evenly mt-1">
    <img className="w-56 h-[3.5rem] grayscale invert ml-5" src="https://i.imgur.com/cqcVg4N.png" />
    <img className="w-56 h-[3.5rem] grayscale invert mr-5" src="https://i.imgur.com/VAdSguB.png" />
  </div>
</div>





<div className="mt-72 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
  <div className="flex flex-col justify-between gap-4 gap-y-8 sm:flex-row sm:gap-x-12 sm:gap-y-0">
    <div className="flex justify-center">
      <div>
        <span className="text-white text-5xl font-semibold">High <span className="text-primary">Performance</span> Servers</span>
        <br/>
        <div className="mt-2"><span className="text-sec text-3xl font-medium">Super fast & reliable servers</span></div>
        <br/>
        <br/>
        <span className="text-white text-2xl font-normal">Our high performance servers are the <span className="text-primary">"bee's knees"</span>.<br/>These are built with the latest Ryzen and Intel chips.<br/>Build for speed and reliability to give you the extra kick you need.</span>
      </div>
    </div>
    <div className="flex justify-center">

<img className="w-[400px] rounded-2xl" src="https://i.imgur.com/uwBVRsm.jpg" />

    </div>
    
  </div>
  <div className="flex flex-col items-center justify-between gap-4 gap-y-8 sm:flex-row sm:gap-x-12 sm:gap-y-0 mt-24">
    <div>
      <div><span className="text-white text-3xl font-semibold"><span className="text-primary">100+</span> Gbps DDoS Protection</span></div>
      <div className="mt-2"><span className="text-sec text-2xl font-medium"><span className="text-primary">Corero</span> inline DDoS Protection ensuring <br/> your server is always protected</span></div>
    </div>
    <div>
      <div><span className="text-white text-3xl font-semibold">Top Teir <span className="text-primary">Network</span></span></div>
      <div className="mt-2"><span className="text-sec text-2xl font-medium">All our locations are serviced by <a className="text-primary underline" target="_blank" href="https://globalsecurelayer.com">Global Secure Layer</a><br/> allowing us to have amazing network performance</span></div>
    </div>
  </div>
</div>




{/* <div className="mt-72">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:px-8">
        <div>
          <h2 className="text-lg font-semibold text-primary">The provider of the future</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-white">Why Choose Us?</p>
          <p className="mt-4 text-lg text-sec">
            Reasons why we will blow your socks off
          </p>
        </div>
        <div className="mt-12 lg:col-span-2 lg:mt-0">
          <dl className="space-y-10 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 sm:space-y-0 lg:gap-x-8">
            {features.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <CheckIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
                  <p className="ml-9 text-lg font-medium leading-6 text-primary">{feature.name}</p>
                </dt>
                <dd className="mt-2 ml-9 text-base text-sec">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div> */}


<Network />
{/* 
<div className="mt-72">
      <div className="mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:px-8">
        <div className="overflow-hidden rounded-lg bg-bgsec shadow-xl lg:grid lg:grid-cols-2 lg:gap-4">
          <div className="px-6 pt-10 pb-12 sm:px-16 sm:pt-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
            <div className="lg:self-center">
              <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                <span className="block">See why SwiftFiber has <span className="text-primary">Albury's</span> best and most innovtive network.</span>
              </h2>
              <p className="mt-4 text-lg leading-6 text-indigo-200">
                SwiftFibers Albury network is designed to give users simple and low latency access to the internet.
              </p>
              <a
                href="/location/albury"
                className="mt-8 inline-flex items-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium text-indigo-600 shadow hover:bg-indigo-50"
              >
                Learn More
              </a>
            </div>
          </div>
          <div className="aspect-w-5 aspect-h-3 -mt-6 md:aspect-w-2 md:aspect-h-1">
            <img
              className="translate-x-8 translate-y-6 transform rounded-md object-cover object-left-top sm:translate-x-8 lg:translate-y-20"
              src="https://haveyoursay.alburycity.nsw.gov.au/application/files/4216/6665/2753/dji_export_1657499134093.jpg"
              alt="App screenshot"
            />
          </div>
        </div>
      </div>
    </div> */}

<div className="mt-72">

<div className="flex justify-center"><span className="text-white text-5xl font-semibold">Services</span></div>
<div className="flex justify-center"><span className="text-sec text-2xl font-medium">Robust services for robust clients</span></div>


      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mt-12 space-y-4 sm:mt-16 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:mx-auto lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-4">
          {tiers.map((tier) => (
            <div key={tier.name} className="divide-y divide-primary rounded-lg border border-primary shadow-sm">
              <div className="p-6">
                <h2 className="text-lg font-medium leading-6 text-white">{tier.name}</h2>
                <p className="mt-4 text-sm text-sec">{tier.description}</p>
                <p className="mt-8">
                  <span className="text-4xl font-bold tracking-tight text-white">${tier.priceMonthly}</span>{' '}
                  <span className="text-base font-medium text-sec">/mo</span>
                </p>
                <a
                  href={tier.href}
                  className="mt-8 block w-full rounded-md border border-primary bg-primary py-2 text-center text-sm font-semibold text-white hover:bg-primarydark"
                >
                  Buy {tier.name}
                </a>
              </div>
              <div className="px-6 pt-6 pb-8">
                <h3 className="text-sm font-medium text-primary">What's included</h3>
                <ul role="list" className="mt-6 space-y-4">
                  {tier.includedFeatures.map((feature) => (
                    <li key={feature} className="flex space-x-3">
                      <CheckIcon className="h-5 w-5 flex-shrink-0 text-green-500" aria-hidden="true" />
                      <span className="text-sm text-white">{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>



</div>


<Reviews />
<Support />



<Footer/>
</div>

</>
  );
}

