import {Footer} from '../components/footer';
import {Nav} from '../components/nav';
import {Reviews} from '../components/reviews';
import {Support} from '../components/support';
import {Network} from '../components/network';
import {
  ArrowPathIcon,
  Bars3Icon,
  ChartBarIcon,
  CursorArrowRaysIcon,
  DocumentChartBarIcon,
  ShieldCheckIcon,
  XMarkIcon,
  MegaphoneIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, ArrowRightIcon, ArrowsRightLeftIcon, CheckIcon, EnvelopeIcon, PhoneIcon, CheckCircleIcon, ChevronRightIcon } from '@heroicons/react/20/solid'

const vpsplans = [
  { name: 'gp.xsmall', cpu: '1 vCore', memory: '2GB', storage: '25GB SSD', bandwidth: '500GB', price: 6 },
  { name: 'gp.small', cpu: '1 vCore', memory: '4GB', storage: '55GB SSD', bandwidth: '1TB', price: 10 },
  { name: 'gp.medium', cpu: '2 vCore', memory: '8GB', storage: '80GB SSD', bandwidth: '1TB', price: 20 },
  { name: 'gp.large', cpu: '4 vCore', memory: '8GB', storage: '120GB SSD', bandwidth: '3TB', price: 40 },
  { name: 'gp.xlarge', cpu: '6 vCore', memory: '16GB', storage: '200GB SSD', bandwidth: '4TB', price: 60 },
  { name: 'gp.xxlarge', cpu: '8 vCore', memory: '16GB', storage: '300GB SSD', bandwidth: '5TB', price: 80 },
  // More people...
]


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}



export function Speed() {
  return (


<>
{/* <div className="fixed inset-x-0 bottom-0 pb-2 sm:pb-5 z-50">
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div className="rounded-lg bg-primary p-2 shadow-lg sm:p-3">
          <div className="flex flex-wrap items-center justify-between">
            <div className="flex w-0 flex-1 items-center">
              <span className="flex rounded-lg bg-primarydark p-2">
                <MegaphoneIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </span>
              <p className="ml-3 truncate font-medium text-white">
                <span className="md:hidden">1000Mbps internet now in Albury!</span>
                <span className="hidden md:inline">Wifiber 1000Mbps mmWave is now available in South Albury!</span>
              </p>
            </div>
            <div className="order-3 mt-2 w-full flex-shrink-0 sm:order-2 sm:mt-0 sm:w-auto">
              <a
                href="#"
                className="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-2 text-sm font-medium text-primary shadow-sm hover:bg-indigo-50"
              >
                Learn more
              </a>
            </div>
            <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
              <button
                type="button"
                className="-mr-1 flex rounded-md p-2 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white"
              >
                <span className="sr-only">Dismiss</span>
                <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div> */}
    
<div className="container mx-auto font-poppins">





<Nav />




<div className="mt-36">

<iframe width="100%" height="650px" frameborder="0" src="https://swiftfiberapp.speedtestcustom.com"></iframe>

</div>

<Support />

<Footer/>
</div>

</>
  );
}

