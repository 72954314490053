import {Footer} from '../components/footer';
import {Nav} from '../components/nav';
import {Reviews} from '../components/reviews';
import {Support} from '../components/support';
import {Network} from '../components/network';
import {
  ArrowPathIcon,
  Bars3Icon,
  ChartBarIcon,
  CursorArrowRaysIcon,
  DocumentChartBarIcon,
  ShieldCheckIcon,
  XMarkIcon,
  MegaphoneIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, ArrowRightIcon, ArrowsRightLeftIcon, CheckIcon, EnvelopeIcon, PhoneIcon, CheckCircleIcon, ChevronRightIcon } from '@heroicons/react/20/solid'

const people = [
  { name: 'Terms of Service', link: 'https://swiftportal.s3.ap-southeast-2.amazonaws.com/website-terms-and-conditions-of-use-services-6953052974251769856.pdf'},
  { name: 'Privacy Policy', link: 'https://swiftportal.s3.ap-southeast-2.amazonaws.com/privacy-policy-6953048674771402752.pdf'},
  { name: 'SLA', link: 'https://docs.google.com/document/d/1FHQlhihobeJTM9cCtqo2cGWnT_JEruPWm4fsXn4l1v4'},
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}



export function Legal() {
  return (


<>
{/* <div className="fixed inset-x-0 bottom-0 pb-2 sm:pb-5 z-50">
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div className="rounded-lg bg-primary p-2 shadow-lg sm:p-3">
          <div className="flex flex-wrap items-center justify-between">
            <div className="flex w-0 flex-1 items-center">
              <span className="flex rounded-lg bg-primarydark p-2">
                <MegaphoneIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </span>
              <p className="ml-3 truncate font-medium text-white">
                <span className="md:hidden">1000Mbps internet now in Albury!</span>
                <span className="hidden md:inline">Wifiber 1000Mbps mmWave is now available in South Albury!</span>
              </p>
            </div>
            <div className="order-3 mt-2 w-full flex-shrink-0 sm:order-2 sm:mt-0 sm:w-auto">
              <a
                href="#"
                className="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-2 text-sm font-medium text-primary shadow-sm hover:bg-indigo-50"
              >
                Learn more
              </a>
            </div>
            <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
              <button
                type="button"
                className="-mr-1 flex rounded-md p-2 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white"
              >
                <span className="sr-only">Dismiss</span>
                <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div> */}
    
<div className="container mx-auto font-poppins">





<Nav />




<div className="mt-36 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mb-72">
  <div className="flex justify-center">
    <span className="text-white text-8xl font-semibold">SwiftFiber <span className="text-primary">Legal</span></span>
  </div>

  <div className="flex justify-center mt-10">
    {/* <span className="text-white text-3xl font-medium">Most connected regional town powered by <span className="text-primary">SwiftFiber</span></span> */}
  </div>
  {/* <div className="flex justify-center mt-10">
      <a
        href="https://cloud.swiftfiber.com.au"
        className="ml-5 inline-flex items-center rounded-md border border-transparent bg-primary px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        Get Started
        <ArrowRightIcon className="ml-3 -mr-1 h-5 w-5" aria-hidden="true" />
      </a>
  </div> */}

</div>






<div className="mt-72">
<div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-bgsec">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-6">
                      Name
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                      Link
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-bgsec">
                  {people.map((person) => (
                    <tr key={person.email}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-white sm:pl-6">
                        {person.name}
                      </td>
                      <a href={person.link} target="_blank"><td className="whitespace-nowrap px-3 py-4 text-sm text-indigo-500">{person.link}</td></a>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>


<Support />

<Footer/>
</div>

</>
  );
}

