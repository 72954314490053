import {Footer} from '../components/footer';
import {Nav} from '../components/nav';
import {Reviews} from '../components/reviews';
import {Support} from '../components/support';
import {Network} from '../components/network';
import { Fragment, useState, useRef, useEffect } from 'react'
import {
  ArrowPathIcon,
  Bars3Icon,
  ChartBarIcon,
  CursorArrowRaysIcon,
  DocumentChartBarIcon,
  ShieldCheckIcon,
  XMarkIcon,
  MegaphoneIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, ArrowRightIcon, ArrowsRightLeftIcon, CheckIcon, EnvelopeIcon, PhoneIcon, CheckCircleIcon, ChevronRightIcon } from '@heroicons/react/20/solid'

import axios from 'axios';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}



export function Dedicatedservers() {

  const [plans, setPlans] = useState([])

useEffect(() => {
    // ref.current.continuousStart()
axios.get("https://api.cloud.swiftfiber.com.au/v1/deploy?service=dedicatedserver", {
})
.then((response) => {
  setPlans(response.data)
  // ref.current.complete()

});
}, []);

  return (


<>

    
<div className="container mx-auto font-poppins">





<Nav />




<div className="mt-36 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mb-72">
  <div className="flex justify-center">
    <span className="text-white sm:text-8xl text-6xl font-semibold">Dedicated Servers<span className="text-primary">.</span></span>
  </div>

  <div className="flex justify-center mt-10">
    <span className="text-white text-3xl font-medium">Super Fast & Reliable <span className="text-primary">Dedicated Servers</span> backed by SwiftFibers Great Network.</span>
  </div>
  <div className="flex justify-center mt-10">
      <a
        href="https://cloud.swiftfiber.com.au"
        className="ml-5 inline-flex items-center rounded-md border border-transparent bg-primary px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        Get Started
        <ArrowRightIcon className="ml-3 -mr-1 h-5 w-5" aria-hidden="true" />
      </a>
  </div>

</div>




<div className="mt-72 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
  <div className="flex flex-col justify-between gap-4 gap-y-8 sm:flex-row sm:gap-x-12 sm:gap-y-0">
    <div className="flex justify-center">
      <div>
        <span className="text-white text-5xl font-semibold">High <span className="text-primary">Performance</span> Dedicated Servers</span>
        <br/>
        <div className="mt-2"><span className="text-sec text-3xl font-medium">Super fast & reliable servers</span></div>
        <br/>
        <br/>
        <span className="text-white text-2xl font-normal">Our high performance dedicated servers are the <span className="text-primary">"bee's knees"</span>.<br/>These are built with the latest Ryzen and Intel chips.<br/>Build for speed and reliability to give you the extra kick you need.</span>
      </div>
    </div>
    <div className="flex justify-center">

<img className="w-[500px] rounded-2xl" src="https://i.imgur.com/RZBIkhd.png" />

    </div>
    
  </div>
  <div className="flex flex-col items-center justify-between gap-4 gap-y-8 sm:flex-row sm:gap-x-12 sm:gap-y-0 mt-24">
    <div>
      <div><span className="text-white text-3xl font-semibold"><span className="text-primary">100+</span> Gbps DDoS Protection</span></div>
      <div className="mt-2"><span className="text-sec text-2xl font-medium"><span className="text-primary">Corero</span> inline DDoS Protection ensuring <br/> your server is always protected</span></div>
    </div>
    <div>
      <div><span className="text-white text-3xl font-semibold">Top Teir <span className="text-primary">Network</span></span></div>
      <div className="mt-2"><span className="text-sec text-2xl font-medium">All our locations are serviced by <a className="text-primary underline" target="_blank" href="https://globalsecurelayer.com">Global Secure Layer</a><br/> allowing us to have amazing network performance</span></div>
    </div>
  </div>
</div>



<div className="mt-72">

<div className="flex justify-center"><span className="text-white text-5xl font-semibold">Plans</span></div>
<div className="flex justify-center"><span className="text-sec text-2xl font-medium mb-24">Take a look at our amazing plans and get started.</span></div>



{plans[1]?.map((application) => (
  <>


<div className="border-2 rounded-xl py-3 pr-10 mb-6 grid grid-cols-1 lg:grid-cols-9 gap-1" Style={`border-color:${
        
          {
            0: '#4e555c38',
            1: '#4e555c38',
            2: '#0fc180'
          }[application.instock]
        

};`}>


  
    <div className="flex justify-center items-center col-span-1"> <img className="w-16 invert" src={`https://d15mqv2ilwv41m.cloudfront.net/company-logos/${application.brand}.svg`} /> </div>
    <div className="flex justify-start items-center col-span-2"> <div><span className="text-xl text-white font-medium">{application.cpus}</span><br/><span className="text-md text-sec">CPU</span></div></div>
    <div className="flex justify-start items-center col-span-1"> <div><span className="text-xl text-white font-medium">{application.ram}</span><br/><span className="text-md text-sec">RAM</span></div></div>
    <div className="flex justify-start items-center col-span-1"> <div><span className="text-xl text-white font-medium">{application.disk}</span><br/><span className="text-md text-sec">SSD</span></div></div>
    <div className="flex justify-start items-center col-span-1"> <div><span className="text-xl text-white font-medium">{application.bw}</span><br/><span className="text-md text-sec">Bandwidth</span></div></div>
    <div className="flex justify-start items-center col-span-1"> <div><span className="text-xl text-white font-medium">Melbourne</span><br/><span className="text-md text-sec">Location</span></div></div>
    <div className="flex justify-between items-center col-span-2 bg-bgsec px-6 py-2 rounded-xl">
      <span className="text-3xl font-bold text-white">${application.price}<span className="text-sm font-light text-white">/mo</span></span>
                            <a href="https://cloud.swiftfiber.com.au/deploy/dedicatedserver" className="text-primary hover:text-primarydark">
                              <button
                                type="button"
                                className="inline-flex items-center rounded border border-transparent bg-primary px-2.5 py-1.5 text-xl font-medium text-white shadow-sm hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              >
                                Deploy
                              </button>
                            </a>
    </div>
  </div>
  </>
))}
{plans[2]?.map((application) => (
  <div className="border-2 rounded-xl py-3 pr-10 mb-6 grid grid-cols-1 lg:grid-cols-9 gap-1" Style={`border-color:${
        
        {
          0: '#4e555c38',
          1: '#4e555c38',
          2: '#0fc180'
        }[application.instock]
      

};`}>
    <div className="flex justify-center items-center col-span-1"> <img className="w-16 invert" src={`https://d15mqv2ilwv41m.cloudfront.net/company-logos/${application.brand}.svg`} /> </div>
    <div className="flex justify-start items-center col-span-2"> <div><span className="text-xl text-white font-medium">{application.cpus}</span><br/><span className="text-md text-sec">CPU</span></div></div>
    <div className="flex justify-start items-center col-span-1"> <div><span className="text-xl text-white font-medium">{application.ram}</span><br/><span className="text-md text-sec">RAM</span></div></div>
    <div className="flex justify-start items-center col-span-1"> <div><span className="text-xl text-white font-medium">{application.disk}</span><br/><span className="text-md text-sec">SSD</span></div></div>
    <div className="flex justify-start items-center col-span-1"> <div><span className="text-xl text-white font-medium">{application.bw}</span><br/><span className="text-md text-sec">Bandwidth</span></div></div>
    <div className="flex justify-start items-center col-span-1"> <div><span className="text-xl text-white font-medium">Sydney</span><br/><span className="text-md text-sec">Location</span></div></div>
    <div className="flex justify-between items-center col-span-2 bg-bgsec px-6 py-2 rounded-xl">
      <span className="text-3xl font-bold text-white">${application.price}<span className="text-sm font-light text-white">/mo</span></span>
                            <a href="https://cloud.swiftfiber.com.au/deploy/dedicatedserver" className="text-primary hover:text-primarydark">
                              <button
                                type="button"
                                className="inline-flex items-center rounded border border-transparent bg-primary px-2.5 py-1.5 text-xl font-medium text-white shadow-sm hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              >
                                Deploy
                              </button>
                            </a>
    </div>
  </div>
))}





</div>


<Reviews />

<Support />

<Footer/>
</div>

</>
  );
}

