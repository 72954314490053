const footerNavigation = {
 main: [
   { name: 'AS149047', href: 'https://ipinfo.io/AS149047' },
   { name: 'Discord', href: 'https://discord.gg/YNHz4p6ubB' },
   { name: 'Legal', href: '/legal' },
 ],
 social: [
   {
     name: 'Facebook',
     href: 'https://www.facebook.com/swiftfiberinternet',
     icon: (props) => (
       <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
         <path
           fillRule="evenodd"
           d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
           clipRule="evenodd"
         />
       </svg>
     ),
   },
 ],
}

export function Footer() {
 return (
  <footer className="mb-10 mt-72">
  <div className="max-w-7xl mx-auto px-4 overflow-hidden sm:px-6 lg:px-8">
    <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
      {footerNavigation.main.map((item) => (
        <div key={item.name} className="px-5 py-2">
          <a href={item.href} className="text-base text-sec hover:text-primary">
            {item.name}
          </a>
        </div>
      ))}
    </nav>
    <div className="mt-8 flex justify-center space-x-6">
      {footerNavigation.social.map((item) => (
        <a key={item.name} href={item.href} className="text-sec hover:text-primary">
          <span className="sr-only">{item.name}</span>
          <item.icon className="h-6 w-6" aria-hidden="true" />
        </a>
      ))}
    </div>
    <p className="mt-8 text-center text-base text-sec">&copy; 2022 SwiftFiber. All rights reserved.</p>
  </div>
</footer>
 )
}