
import { ArrowsRightLeftIcon } from '@heroicons/react/20/solid'
export function Network() {
 return (
<div className="mt-72 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
  <div className="flex flex-col justify-between gap-4 gap-y-8 sm:flex-row sm:gap-x-12 sm:gap-y-0">
    <div className="flex justify-center">
      <div>
        <span className="text-white text-5xl font-semibold">Low<span className="text-primary">-</span>Latency network</span>
        <br/>
        <div className="mt-2"><span className="text-sec text-3xl font-medium">The network for the best of the best</span></div>
        <br/>
        <br/>
        <span className="text-white text-2xl font-normal">Our network is designed to have the <br/> lowest latency possible while <br/> providing world class DDoS Protection</span>
      </div>
    </div>
    <div className="flex justify-center">
      <div className="bg-bgsec rounded-2xl">
        <div className="px-16 py-10">
          <span className="text-white text-3xl font-medium">Take a look under the hood</span>
          <br/>
          <br/>
          <br/>

        <div className="flex justify-between">
          <div className="flex justify-start align-center items-center">
            <span className="text-white text-1xl font-normal mr-2">Melbourne</span>
            <ArrowsRightLeftIcon className="h-5 w-5 text-primary" aria-hidden="true" />
            <span className="text-white text-1xl font-normal ml-2">Sydney</span>
          </div>
          <div >
            <span className="text-primary text-1xl font-normal">~10ms</span>
          </div>
        </div>
        <div className="flex justify-between mt-2">
          <div className="flex justify-start align-center items-center">
            <span className="text-white text-1xl font-normal mr-2">Melbourne</span>
            <ArrowsRightLeftIcon className="h-5 w-5 text-primary" aria-hidden="true" />
            <span className="text-white text-1xl font-normal ml-2">New York</span>
          </div>
          <div >
            <span className="text-primary text-1xl font-normal">~202ms</span>
          </div>
        </div>
        <div className="flex justify-between mt-2">
          <div className="flex justify-start align-center items-center">
            <span className="text-white text-1xl font-normal mr-2">Albury</span>
            <ArrowsRightLeftIcon className="h-5 w-5 text-primary" aria-hidden="true" />
            <span className="text-white text-1xl font-normal ml-2">Sydney</span>
          </div>
          <div >
            <span className="text-primary text-1xl font-normal">~6ms</span>
          </div>
        </div>
        <div className="flex justify-between mt-2">
          <div className="flex justify-start align-center items-center">
            <span className="text-white text-1xl font-normal mr-2">Albury</span>
            <ArrowsRightLeftIcon className="h-5 w-5 text-primary" aria-hidden="true" />
            <span className="text-white text-1xl font-normal ml-2">Melbourne</span>
          </div>
          <div >
            <span className="text-white text-1xl font-normal">TBA</span>
          </div>
        </div>
        <div className="flex justify-between mt-2">
          <div className="flex justify-start align-center items-center">
            <span className="text-white text-1xl font-normal mr-2">Morwell</span>
            <ArrowsRightLeftIcon className="h-5 w-5 text-primary" aria-hidden="true" />
            <span className="text-white text-1xl font-normal ml-2">Melbourne</span>
          </div>
          <div >
            <span className="text-primary text-1xl font-normal">~4ms</span>
          </div>
        </div>

        <div className="flex justify-center mt-6"><span className="text-sec text-md font-medium">Live stats from our network </span></div>
        </div>
      </div>
    </div>
    
  </div>
  <div className="flex flex-col items-center justify-between gap-4 gap-y-8 sm:flex-row sm:gap-x-12 sm:gap-y-0 mt-24">
    <div>
      <div><span className="text-white text-3xl font-semibold"><span className="text-primary">100+</span> Gbps DDoS Protection</span></div>
      <div className="mt-2"><span className="text-sec text-2xl font-medium"><span className="text-primary">Corero</span> inline DDoS Protection ensuring <br/> your network is always protected</span></div>
    </div>
    <div>
      <div><span className="text-white text-3xl font-semibold">Top Teir <span className="text-primary">Network</span></span></div>
      <div className="mt-2"><span className="text-sec text-2xl font-medium">All our locations are serviced by <a className="text-primary underline" href="https://globalsecurelayer.com">Global Secure Layer</a><br/> allowing us to have amazing network performance</span></div>
    </div>
  </div>
</div>
 )
}