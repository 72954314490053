import {Footer} from '../components/footer';
import {Nav} from '../components/nav';
import {Reviews} from '../components/reviews';
import {Support} from '../components/support';
import {Network} from '../components/network';
import {Search} from '../components/addresssearch';
import { Fragment, useState, useRef, useEffect } from 'react'
import {
  ArrowPathIcon,
  Bars3Icon,
  ChartBarIcon,
  CursorArrowRaysIcon,
  DocumentChartBarIcon,
  ShieldCheckIcon,
  XMarkIcon,
  MegaphoneIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, ArrowRightIcon, ArrowsRightLeftIcon, CheckIcon, EnvelopeIcon, PhoneIcon, CheckCircleIcon, ChevronRightIcon } from '@heroicons/react/20/solid'

import axios from 'axios';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}



export function Wifiber() {

  var plans = {
    "plans":[
             {
    
                "name":"Wifiber 100",
                "speed":"100\/100",
                "price":79,
                "setup":"99",
                "greatFor":[
                  "Streaming 4K/8K movies and shows", "Downloading and uploading massive files", "Online gaming", "Working from home", "loads of devices"
                ]
             },
             {
                "name":"Wifiber 150",
                "speed":"150\/150",
                "price":99,
                "setup":"99",
                "greatFor":[
                  "Streaming 4K/8K movies and shows", "Downloading and uploading massive files", "Online gaming", "Working from home", "loads of devices"
                ]
             },
             {
              "name":"Wifiber 200",
              "speed":"200\/200",
                "price":119,
                "setup":"99",
                "greatFor":[
                  "Streaming 4K/8K movies and shows", "Downloading and uploading massive files", "Online gaming", "Working from home", "loads of devices"
                ]
             },
             {
              "name":"Wifiber 250",
                "speed":"250\/250",
                "price":149,
                "setup":"99",
                "greatFor":[
                  "Streaming 4K/8K movies and shows", "Downloading and uploading massive files", "Online gaming", "Working from home", "loads of devices"
                ]
             },
          ]
       }
    

  return (


<>

    
<div className="container mx-auto font-poppins">





<Nav />




<div className="mt-36 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mb-72">
  <div className="flex justify-center">
    <span className="text-white text-8xl font-semibold"><span className="text-primary">Wifiber</span> Internet</span>
  </div>

  <div className="flex justify-center mt-10">
    <span className="text-white text-3xl font-medium">Blazing fast internet in <span className="text-primary">Albury</span> & <span className="text-primary">Latrobe Valley</span>.</span>
  </div>
  <div className="flex justify-center mt-10">
{/* <Search/> */}
  </div>

</div>



<div className="mt-72 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
  <div className="flex flex-col justify-between gap-4 gap-y-8 sm:flex-row sm:gap-x-12 sm:gap-y-0">
    <div className="flex justify-center">
      <div>
        <span className="text-white text-5xl font-semibold">Internet Speeds that <span className="text-primary">"Make Sense"</span></span>
        <br/>
        <div className="mt-2"><span className="text-sec text-3xl font-medium">The only broadband network you need</span></div>
        <br/>
        <br/>
        <span className="text-white text-2xl font-normal">SwiftFibers custom built network is built to support the needs of todays internet users. Setup is as simple as installing a small antenna on your roof.</span>
      </div>
    </div>
    <div className="flex justify-center">

<img className="w-[400px] rounded-2xl" src="https://i.imgur.com/EHWlwyl.jpg" />

    </div>
    
  </div>
  <div className="flex flex-col items-center justify-between gap-4 gap-y-8 sm:flex-row sm:gap-x-12 sm:gap-y-0 mt-24">
    <div>
      <div><span className="text-white text-3xl font-semibold"><span className="text-primary">300Mbps</span> Average Internet Speed</span></div>
      <div className="mt-2"><span className="text-sec text-2xl font-medium">SwiftFiber users average an internet speed of 300Mbps+</span></div>
    </div>
    <div>
      <div><span className="text-white text-3xl font-semibold">Simple <span className="text-primary">Setup</span></span></div>
      <div className="mt-2"><span className="text-sec text-2xl font-medium">Setup in as little as one day.</span></div>
    </div>
  </div>
</div>



<div className="mt-72">

<div className="flex justify-center"><span className="text-white text-5xl font-semibold">Plans</span></div>
<div className="flex justify-center"><span className="text-sec text-2xl font-medium mb-24">Take a look at our amazing plans and get started.</span></div>


<div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mt-12 space-y-4 sm:mt-16 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:mx-auto lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-4">
          {plans.plans.map((tier) => (
            <div key={tier.name} className="divide-y divide-primary rounded-lg border border-primary shadow-sm">
              <div className="p-6">
                <h2 className="text-lg font-medium leading-6 text-white">{tier.name}</h2>
                <p className="mt-4 text-sm text-sec">{tier.speed} Mbps</p>
                <p className="mt-8">
                  <span className="text-4xl font-bold tracking-tight text-white">${tier.price}</span>{' '}
                  <span className="text-base font-medium text-sec">/mo</span>
                </p>
                <a
                  href="https://forms.gle/PTHXenYuP9kXVk8D6"
                  className="mt-8 block w-full rounded-md border border-primary bg-primary py-2 text-center text-sm font-semibold text-white hover:bg-primarydark"
                >
                  Buy {tier.name}
                </a>
              </div>
              <div className="px-6 pt-6 pb-8">
                <h3 className="text-sm font-medium text-primary">Great For</h3>
                <ul role="list" className="mt-6 space-y-4">
                {tier.greatFor.map((feature) => (
                    <li key={feature} className="flex space-x-3">
                      <CheckIcon className="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
                      <span className="text-sm text-white">{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>



</div>


<Reviews />

<Support />

<Footer/>
</div>

</>
  );
}

