import logo from './logo.svg';
import './App.css';
import {Home} from './pages/home';
import {Virtualservers} from './pages/virtualservers';
import {Dedicatedservers} from './pages/dedicatedservers';
import {Albury} from './pages/albury';
import {Sq} from './pages/sq';
import {Nbn} from './pages/nbn';
import {Speed} from './pages/speedtest';
import {Wifiber} from './pages/wifiber';
import {Legal} from './pages/legal';
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  Outlet
 } from "react-router-dom";



function App() {
  return (

    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="/virtualservers" element={<Virtualservers />}/>
        <Route path="/dedicatedservers" element={<Dedicatedservers />}/>
        <Route path="/location/albury" element={<Albury />}/>
        <Route path="/speed" element={<Speed />}/>
        <Route path="/sq/:loc" element={<Sq />}/>
        <Route path="/nbn" element={<Nbn />}/>
        <Route path="/wifiber" element={<Wifiber />}/>
        <Route path="/legal" element={<Legal />}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
